function slideout_menu(){
  var slideout = new Slideout({
    'panel': document.getElementById('js-so_panel'),
    'menu': document.getElementById('js-so_menu'),
    'duration': 10,
  });
  var nodeList = document.querySelectorAll('#js-so_toggle, #js-so_panel');
  var node = Array.prototype.slice.call(nodeList,0);
  node.forEach(function(elem, index){
    elem.addEventListener('click', function() {
      slideout.toggle();
    });
  });
}


$(function() {
/*********************************************************************
  common
*********************************************************************/
slideout_menu();

/*********************************************************************
  index
*********************************************************************/
$(function(){
  $(".js-btn").on("click", function() {
    $(this).next().slideToggle();
    });
  });
  $('.js-aco').on('click',function(){
    $($(this).attr('href')).slideToggle();
    return false;
  });
});
$(window).on('load',function(){
});
$(window).on('scroll',function(){
});
$(window).on('resize',function(){
});
/*********************************************************************
  onlin shop
*********************************************************************/


$(function() {
  $('.products_list').each(function(){
      let type = $(this).data('pager-name'),
          page = $(this).data('page');
    $(this).pagination({
      itemElement              : '> li',
      defaultDisplayPageNumber : page,
      displayItemCount         : 9,
      paginationClassName      : 'pagination',
      pageNumberDisplayNumber    :15,
      firstEndPageBtnMode      : true,
      changeEndCallback   : function(page){
        let paged = page.activePageNumber,
            url   = new URL(location);            
            url.searchParams.set(type, paged);
        let paramStr = url.toString();
        history.pushState('','',paramStr);
      }
    });

  });
});

$('.js-tab_wrap').each(function(){
  var init_tab = $(this).children('.js-tab_trg').children('li').eq(0).children('a');
  var init_tab_cont=init_tab.attr('data-tab');
  init_tab.addClass('is-act');
  $(this).children('.js-tab_cont').find(init_tab_cont).addClass('is-act').fadeIn();
});

$('.js-tab_trg a').on('click',function(){
  var target=$(this).parents('.js-tab_wrap').children('.js-tab_cont').find($(this).attr('data-tab'));
  $(this).parents('.js-tab_wrap').children('.js-tab_trg').find('a').removeClass('is-act');
  $(this).addClass('is-act');
  target.siblings().removeClass('is-act').hide();
  target.addClass('is-act').fadeIn();
});